import React from "react";
import './panda.css';
import client from '../../images/client.png';
import CueBad from '../../images/logowithupperimg.png';

const Pandasection = () => {
  return (
    <section className="pandain">
      <div className="container mt-5 d-flex justify-content-center align-items-center">
        <div className="row panda-section">
          {/* Logo Section */}
          <div className="col-md-4 text-center">
            <img
              src={CueBad}
              alt="Bad SkullLogo"
              className="panda-logo"
            />
          </div>

          {/* Features Section */}
          <div className="myBad Skull col-md-7 col-xs-12 mx-auto mt-lg-5">
            <h4 className="mainpanda">Why Panda's Playhouse?</h4>
            <ul className="feature-list">
              <li className="d-flex gap-3 align-items-center text-start">Features hundreds of casino-style games.</li>
              <li className="d-flex gap-3 align-items-center text-start">Easy checkout system makes it a breeze to upgrade your experience.</li>
              <li className="d-flex gap-3 align-items-center text-start">
                Looking for reliable and top-notch customer support? Panda's
                Playhouse offers VIP assistance for any questions you may have.
              </li>
            </ul>
            <div className="live-support">
              <img src={client} alt="User Image" />
              <span>
                28K+ <br /> Active Users
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pandasection;
