import React from 'react';

export default function SpeedPaymentPopup(props) {
  return (
    <div className="modal fade show" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'block' }}>
      <div className="modal-dialog modal-fullscreen modal-dialog-scrollable">
        <div className="modal-content bg-dark text-white">
          <div className="modal-header d-flex justify-content-between border-0">
            <h1 className="modal-title fs-5" id="exampleModalLabel">Payment</h1>
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={props.onClose}>Close</button>
          </div>
          <div className="modal-body bg-light p-0">
            <iframe
              src={props.src}
              width="100%"
              height="100%"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

