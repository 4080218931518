import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../Sweepstakes/Sweepstakes.css'// Add custom styles if needed
import Header from "../../components/Header";
import Footer from "../../components/Footer";

function PrivacyPolicy() {
    return (
        <>
            <Header />
            <div className="Sweepstakes_bg">
                <div className="container text-light py-5">
                    <h1 className="text-center mb-4">Privacy Policy</h1>
                    <p className="text-light text-center">Last updated: February 13, 2024</p>
                    <p>
                        This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
                        information when You use the Service and tells You about Your privacy rights and how the law protects You.
                    </p>
                    <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>

                    <h3>Interpretation and Definitions</h3>
                    <h4>Interpretation</h4>
                    <p>
                        The words of which the initial letter is capitalized have meanings defined under the following conditions.
                        The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
                    </p>
                    <h4>Definitions</h4>
                    <ul>
                        <li>
                            <strong>Account:</strong> A unique account created for You to access our Service or parts of our Service.
                        </li>
                        <li>
                            <strong>Affiliate:</strong> An entity that controls, is controlled by or is under common control with a party, where “control” means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.
                        </li>
                        <li>
                            <strong>Company:</strong> Refers to Andhera Deals LLC, Georgia.
                        </li>
                        <li>
                            <strong>Cookies:</strong> Small files placed on Your device by a website, containing details of browsing history.
                        </li>
                        <li>
                            <strong>Personal Data:</strong> Any information that relates to an identified or identifiable individual.
                        </li>
                    </ul>

                    <h3>Collecting and Using Your Personal Data</h3>
                    <h4>Types of Data Collected</h4>
                    <h4>Personal Data</h4>
                    <p>
                        While using Our Service, We may ask You to provide Us with certain personally identifiable information
                        that can be used to contact or identify You. Personally identifiable information may include:
                    </p>
                    <ul>
                        <li>First name and last name</li>
                        <li>Address, State, Province, ZIP/Postal code, City</li>
                    </ul>
                    <h4>Usage Data</h4>
                    <p>
                        Usage Data is collected automatically when using the Service. It may include information such as Your Device’s
                        Internet Protocol (IP) address, browser type, browser version, the pages of our Service that You visit, and other diagnostic data.
                    </p>

                    <h3>Tracking Technologies and Cookies</h3>
                    <p>
                        We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information.
                    </p>
                    <ul>
                        <li>
                            <strong>Necessary Cookies:</strong> Essential for the website to function properly.
                        </li>
                        <li>
                            <strong>Functionality Cookies:</strong> Help remember user preferences to provide a better experience.
                        </li>
                    </ul>

                    <h3>Use of Your Personal Data</h3>
                    <p>The Company may use Personal Data for the following purposes:</p>
                    <ul>
                        <li>To provide and maintain our Service.</li>
                        <li>To contact You regarding updates or information.</li>
                        <li>To manage Your requests and account.</li>
                    </ul>

                    <h3>Retention of Your Personal Data</h3>
                    <p>
                        We will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy.
                    </p>

                    <h3>Security of Your Personal Data</h3>
                    <p>
                        The security of Your Personal Data is important to Us, but no method of transmission over the Internet is 100% secure.
                    </p>

                    <h3>Changes to this Privacy Policy</h3>
                    <p>
                        We may update Our Privacy Policy from time to time. You are advised to review this Privacy Policy periodically for any changes.
                    </p>

                    <h3>Contact Us</h3>
                    <p>
                        If you have any questions about this Privacy Policy, You can contact us:
                    </p>
                    <ul>
                        <li>By email: support@badskullgaming.com</li>
                        <li>By visiting this page on our website: http://badskullgaming.com/Support</li>
                    </ul>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default PrivacyPolicy;
