import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./Pages/Home";
import Faqs from "./Pages/Faqs";
import Deposit from "./Pages/Deposit";
import Support from "./Pages/Support";
import CheckoutForm from "./Pages/CheckoutForm";
import TestForm from "./Pages/CartSectionForm";
import ShoppingCart from "./Pages/ShoppingCart";
import Payment from './Pages/Payment/'
import Sweepstakes from "./Pages/Sweepstakes";
import TermsAndConditions from "./Pages/TermsAndConditions";
import TermsOfUse from "./Pages/TermsOfUse";
import RefundPolicy from "./Pages/RefundPolicy";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import AmlPolicy from "./Pages/AmlPolicy";
import ResponsibleSocialGameplay from "./Pages/ResponsibleSocialGameplay";


const App = () => {
  return (
    <>
      <Router>
      <ScrollToTop /> {/* Include the ScrollToTop component */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Faqs" element={<Faqs />} />
          <Route path="/Deposit" element={<Deposit />} />
          <Route path="/Support" element={<Support />} />
          <Route path="/CheckoutForm" element={<CheckoutForm />} />
          <Route path="/TestForm" element={<TestForm />} />
          <Route path="/ShoppingCart" element={<ShoppingCart />} />
          <Route path="/Payment" element={<Payment />} />
          <Route path="/Sweepstakes" element={<Sweepstakes />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions/>} />
          <Route path="/TermsOfUse" element={<TermsOfUse/>} />
          <Route path="/RefundPolicy" element={<RefundPolicy/>} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>} />
          <Route path="/AmlPolicy" element={<AmlPolicy/>} />
          <Route path="/ResponsibleSocialGameplay" element={<ResponsibleSocialGameplay/>} />
        </Routes>
      </Router>

    </>
  )
}
export default App;
