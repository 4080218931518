import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import "./CheckoutForm.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SpeedPaymentPopup from "../../components/SpeedPaymentPopup";

const CheckoutForm = () => {
  const [data, setData] = useState({});
  const [gameId, setGameId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [checkoutURL, setCheckoutURL] = useState("");
  const [modalOpen, setModalOpen] = useState(false); // State to control modal visibility
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [title, setTitle] = useState("");

  const getData = async () => {
    const storedData = JSON.parse(localStorage.getItem('credits'));
    if (storedData && storedData.credits) {
      setData(storedData);
    } else {
      console.error("Stored data is invalid or credits are not available");
    }
  };

  const createCheckoutSession = async () => {
    if (!gameId) {
      setErrorMessage("Game ID is required to proceed with the payment.");
      return;
    }
    if (data && data.credits) {
      try {
        const amountToSend = parseInt(data.price);
        const response = await axios.post('https://api.badskullgaming.com/api/create-checkout-session', {
          currency: "USD",
          amount: amountToSend,
          game_id: gameId,
          title: title
        });
        setCheckoutURL(response.data.checkout_url);
        setModalOpen(true); // Open the modal when checkout URL is set
      } catch (error) {
        console.error("Error creating checkout session:", error);
        setErrorMessage("Failed to create checkout session.");
      }
    } else {
      setErrorMessage("Invalid order data or credits not available.");
    }
  };

  useEffect(() => {
    getData();
    const titleFromURL = searchParams.get('title');
    if (titleFromURL) {
      setTitle(decodeURIComponent(titleFromURL));
    }
  }, [searchParams]);

  return (
    <>
      <Header />
      <div className="main_checkout">
        <div className="container py-5">
          <div className="row">
            {/* Title Display */}
            <div className="col-12 mb-4">
              <label className="form-label text-white fw-bold">Game Name</label>
              <input
                type="text"
                className="form-control"
                value={title}
                disabled
              />
            </div>

            {/* Game ID Input */}
            <div className="col-12 mb-4">
              <label htmlFor="gameId" className="form-label text-white fw-bold">Game ID</label>
              <input
                type="text"
                id="gameId"
                className="form-control"
                placeholder="Enter your Game ID here"
                value={gameId}
                onChange={(e) => setGameId(e.target.value)}
              />
              {errorMessage && <div className="alert alert-danger mt-2">{errorMessage}</div>}
            </div>

            {/* Order Table */}
            <div className="col-12 mb-4">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="bg-primary text-white">Product</th>
                    <th className="text-end bg-primary text-white">Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {title && <strong>{title}</strong>}<br />
                      {data.name ? `${data.name} - ${data.number} x ${data.credits}` : 'Loading...'}
                    </td>
                    <td className="text-end">{data.price ? `$${data.price * data.credits}.00` : 'Calculating...'}</td>
                  </tr>
                  <tr className="fw-bold">
                    <td>Subtotal</td>
                    <td className="text-end">{data.price ? `$${data.price * data.credits}.00` : 'Calculating...'}</td>
                  </tr>
                  <tr className="fw-bold">
                    <td>Total</td>
                    <td className="text-end">{data.price ? `$${data.price * data.credits}.00` : 'Calculating...'}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Payment Section */}
            <div className="col-12 mb-4">
              <div className="payment-method p-3 bg-light">
                <h5>Bitcoin</h5>
                <p>
                  CashApp, Strike App, Coinbase, Kraken, and more
                </p>
              </div>
            </div>

            {/* Privacy Policy Note */}
            <div className="col-12 mb-4">
              <p className="text-white">
                Your personal data will be used to process your order, support your experience throughout this website,
                and for other purposes described in our <a href="/privacy-policy">privacy policy</a>.
              </p>
            </div>

            {/* Place Order Button */}
            <div className="col-12 text-end">
              <button className="place-order-btn" onClick={createCheckoutSession}>
                Place Order
              </button>
            </div>
          </div>
        </div>
      </div>
      {modalOpen && <SpeedPaymentPopup src={checkoutURL} onClose={() => setModalOpen(false)} />}
      <Footer />
    </>
  );
};

export default CheckoutForm;

