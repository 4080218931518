import img01 from '../../images/banner.jpg';
import React from "react";
import { Link } from 'react-router-dom';
const Hero = () => {
  return (
    <>
      <div className="hero-section ">
        {/* Hero Image Overlay */}
        {/* <img src={img01} alt="" className="hero-section-img" /> */}
        <div className="hero-overlay ">
          <div>
            <h1>AMERICA'S  #1 SWEEPSTAKES EXPERIENCE</h1>
            <Link to='/Deposit' className='text-decoration-none text-white'>
              <button className="play-btn">
                PLAY NOW
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
export default Hero;
