import React, { useState } from 'react';
import { Speed } from '@speeddev/speed-js';

const SpeedPayment = () => {
  const [loading, setLoading] = useState(false);

  const speed = new Speed("pk_live_m3fka1cmmzhoKOKym3fsn43wymDATYzHm3fsn43wkI4uF22z");  // Replace with your actual public key

  // Function to handle the payment process
  const handlePayment = async () => {
    setLoading(true);

    try {
      // Try to create a checkout session with the provided API key
      const session = await speed.createCheckoutSession({
        currency: "USD",
        amount: 1000,  // Example amount in cents
        successUrl: "https://yourwebsite.com/success",
        cancelUrl: "https://yourwebsite.com/cancel",
        successMessage: "Thank you for your payment!",
        paymentMethods: ["onchain", "lightning"],
        metadata: {
          customerID: "12345",
          orderID: "abcde"
        }
      });

      // If session creation is successful, log the session
      console.log('Checkout session:', session);
    } catch (error) {
      // Catch the error and log more details
      if (error.response && error.response.errors) {
        error.response.errors.forEach(err => {
          console.error(`Error Type: ${err.type}, Message: ${err.message}`);
        });
      } else {
        console.error('Error creating payment session:', error);
      }
    }
  };

  return (
    <div>
      <button onClick={handlePayment} className="border border-secondary-emphasis text-secondary bg-white mt-2 w-100 py-2 rounded-1" disabled={loading}>
        {loading ? 'Processing...' : 'Pay with Bitcoin'}
      </button>
    </div>
  );
};

export default SpeedPayment;

