import React, { useEffect, useState } from "react";
import "./CardSection.css";
import { Link } from "react-router-dom";

const API_URL = "https://api.badskullgaming.com/api/get-deposit";

const CardSection = () => {
  const [cardData, setCardData] = useState([]);
  const [imagePath, setImagePath] = useState("");

  // Fetching data from API
  const fetchCardData = async () => {
    try {
      const response = await fetch(API_URL);
      const data = await response.json();
      if (data.status === 200) {
        setCardData(data.deposit);
        setImagePath(data.imagePath);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchCardData();
  }, []);

  return (
    <main>
      <section className="bg-image">
        <div className="container py-5">
          <div className="row">
            {cardData.map((card) => (
              <div className="col-card mt-5" key={card.id}>
                <div className="card bg-transparent shadow-lg">
                  {/* Using the fetched image path */}
                  <img
                    src={
                      card.image
                        ? `${imagePath}/${card.image}`
                        : "https://via.placeholder.com/150" // Fallback image if card.image is null
                    }
                    className="card-img-top"
                    alt={card.title}
                  />
                  <div className="card-body text-center mt-1">
                    <h5 className="card-title text-white">{card.title}</h5>
                    <h6 className="price-color p-1">
                      ${card.from_price} – ${card.to_price}
                    </h6>
                    <Link 
                      to={`/TestForm?id=${card.id}&title=${encodeURIComponent(card.title)}`} 
                      className="btn btn-bg mt-2"
                    >
                      <b className="p-md-4 p-xs-0 lh-lg text-light">BUY CREDITS</b>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </main >
  );
};

export default CardSection;
