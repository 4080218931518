import React from 'react';
import { Container } from 'react-bootstrap';
import '../Sweepstakes/Sweepstakes.css'
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const TermsAndConditions = () => {
    return (
        <>
            <Header />
            <div className="Sweepstakes_bg">
                <Container className="py-5 text-light">
                    <h1 className="text-center mb-4">Terms and Conditions</h1>
                    <p className="text-light text-center mb-4">Date of Last Update: 03 September 2024</p>

                    <p>
                        These Terms and Conditions form a binding legal agreement between you and us and apply to your use of any of our Games or our Platform in any way, through any electronic device (web, mobile, tablet or any other device).
                    </p>
                    <p>
                        <strong>Please Note:</strong> These Terms and Conditions include a provision waiving the right to pursue any class, group, or representative claim and requiring you to pursue past, pending, and future disputes between you and us through individual arbitration unless you opt out within the specified time frame. See Clause 22 for more information.
                    </p>

                    <h3>Eligibility</h3>
                    <p>
                        Only players in the United States (excluding the states of Idaho and Washington) and Canada (excluding Quebec) are eligible to enter the sweepstakes. Please refer to Clause 1.1 of the Sweeps Rules to check your eligibility.
                    </p>

                    <h3>Participation</h3>
                    <p>
                        You must read these Terms and Conditions carefully in their entirety before using our product, requesting free credits, creating an account, or purchasing credits. All these actions are considered as an express agreement to our terms.
                    </p>

                    <h3>Licensing and the Protection of Funds</h3>
                    <p>
                        Funds equal to the aggregate value of Prizes which Registered Customers have chosen to leave unredeemed are held by Bad Skull Fishing Room Games in accounts separate from its business account. This measure is taken to protect such funds, but in the event of insolvency there is no absolute guarantee that any Prizes which you have not yet redeemed will be paid to you.
                    </p>

                    <h3>Dispute Resolution</h3>
                    <p>
                        Any disputes will be handled through individual arbitration unless you opt out. This arbitration agreement significantly affects your rights, including preventing you from participating in class actions or similar proceedings.
                    </p>
                </Container>
            </div>
            <Footer />
        </>
    );
};

export default TermsAndConditions;
