import React from "react";
import './Sweepstakes.css';
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const Sweepstakes = () => {
    const sections = [
        {
            id: "eligibility",
            title: "Eligibility",
            content: `
                The Promotions are open to Players in the United States who do not reside in an Excluded Territory and meet all other conditions specified in the rules. Players must own a Customer Account, agree to the Promo Rules, and comply with applicable laws. Immediate family members of Bad Skull Fishing Room employees or agents are not eligible.
            `,
        },
        {
            id: "promotional-period",
            title: "Promotional Period",
            content: `
                Promotions run concurrently with Bad Skull Fishing Room's business of selling Gold Coins. They may end on December 31st of the current year or with 30 days’ notice of termination. Promotions are subject to cancellation or modification due to external factors.
            `,
        },
        {
            id: "sweepstakes-coins",
            title: "Sweepstakes Coins",
            content: `
                Sweepstakes Coins can be earned through daily logins, social media activities, mail-in requests, referrals, or Gold Coin purchases. They expire 60 days after the last login and cannot be purchased directly. Tampering with entry processes will result in disqualification.
            `,
        },
        {
            id: "games-prizes",
            title: "Games and Prizes",
            content: `
                Sweepstakes Coins can be used for Promotional Play to win Prizes. Odds of winning depend on game mechanics and coins used. Prizes can be redeemed for cash or other real-world value after meeting verification and playthrough requirements.
            `,
        },
        {
            id: "publicity",
            title: "Publicity",
            content: `
                By participating, you consent to the use of your name, likeness, and other personal details for promotional purposes without further compensation unless prohibited by law.
            `,
        },
        {
            id: "damages-disqualification",
            title: "Damages and Disqualification",
            content: `
                Bad Skull Fishing Room reserves the right to disqualify anyone violating rules or acting disruptively. Fraud or tampering will result in disqualification and possible legal action.
            `,
        },
    ];

    return (
        <>
            <Header />
            <div className="Sweepstakes_bg">
                <div className="container py-5">
                    <h1 className="text-center text-light mb-4">Sweepstakes Rules</h1>
                    <p className="text-light text-center mb-4">
                        Last updated: February 17th, 2024
                    </p>
                    {sections.map((section) => (
                        <div key={section.id} className="mb-4 bg-transparent">
                            <h3 className="section-title- bg-transparent text-light">{section.title}</h3>
                            <p className="section-content text-light">{section.content}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Sweepstakes;
