import React from "react";
import "./step.css";
const Steps = () => {
  return (
    <>
      <section className="easy-steps-section ">
        <div className="easystep container py-5 px-5">
          <h2 className="easy-steps-title">Start Playing In 3 Easy Steps.</h2>
          <p className="easy-steps-subtitle">GET UP TO 100% BONUS TODAY!</p>
          <div className="row text-start">
            {/* Step 1 */}
            <div className="col-md-4 mb-3 px-0">
              <div className="step-card">
                <div>
                  <span className="step-number ms-2">1</span>
                  <span>Choose Your Game</span>
                </div>
                <hr />
                <p className="px-2">
                  Browse our selection of exciting games, from fish tables to
                  slots, and pick your favorite. Dive into the action with just
                  a click!
                </p>
              </div>
            </div>
            {/* Step 2 */}
            <div className="col-md-4 mb-3 px-0">
              <div className="step-card">
                <div>
                  <span className="step-number ms-2">2</span>
                  <span>Make a Deposit</span>
                </div>
                <hr />
                <p className="px-2">
                  During checkout, just enter your Game ID at checkout and
                  choose your preferred payment method!
                </p>
              </div>
            </div>
            {/* Step 3 */}
            <div className="col-md-4 mb-3 px-0">
              <div className="step-card">
                <div>
                  <span className="step-number ms-2">3</span>
                  <span>Redeem Your Winnings</span>
                </div>
                <hr />
                <p className="px-2">
                  When you're ready, visit our Redeem page to your winnings.
                  Simply fill in your details, and our team will review and
                  process your request quickly!
                </p>
              </div>
            </div>
          </div>
          {/* Deposit Button */}
          <div className="d-flex justify-content-start">
            <a href="/Deposit">
              <button className="play-btn">DEPOSIT NOW</button>
            </a>
          </div>
        </div>
      </section>
    </>
  );
};
export default Steps;
