import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Sweepstakes/Sweepstakes.css'
import Header from '../../components/Header';
import Footer from '../../components/Footer';

function TermsOfUse() {

    
    return (
        <>
            <Header />
            <div className="Sweepstakes_bg">
                <div className="container text-light py-5">
                    <h1 className="text-center mb-4">Terms of Use</h1>
                    <div className="text-justify">
                        <h3>Is Bad Skull’s Playhouse Legal?</h3>
                        <p>Bad Skull’s Playhouse is a leading social gaming platform. In addition to our Gold Coin games, we use a unique sweepstakes model which allows the safe and secure redemption of Sweeps Coins for cash prizes to players in the US and Canada. We pride ourselves on adhering to the laws in the jurisdictions that we operate in. We engage with leading experts in those jurisdictions to ensure that we are fully compliant with all applicable laws.</p>

                        <h3>Who is Bad Skull’s Playhouse?</h3>
                        <p>Bad Skull’s Playhouse is a social gaming platform owned and operated by Andhera Deals LLC.</p>

                        <h3>What are “Sweepstakes”?</h3>
                        <p>Sweepstakes are trade promotions with prizes. They are used to promote the sales of products in our case – our Gold Coins – through prize giveaways and have been used for decades, worldwide.</p>

                        <h3>How does Bad Skull’s Playhouse’s Sweepstakes Model Work?</h3>
                        <p>Bad Skull’s Playhouse sells Gold Coins, a virtual currency which you can accumulate and play with, but which cannot be traded, transferred or redeemed for real money.</p>

                        <h3>Identity Verification Process</h3>
                        <p>Collection of information relating to identity and verification of identity is necessary before any purchase of Gold Coins and redemption of Sweeps Coins.</p>

                        <h3>What Documents Do I Need to Provide?</h3>
                        <p>Verification may include submitting documents such as a driver’s license, passport, or identity card. Proof of residential address might be needed as well.</p>

                        <h3>How Long Does it Take to Verify My Documents?</h3>
                        <p>Once we have received the required documents, it normally takes about 5 days to complete the account verification process.</p>

                        <h3>What’s the Difference Between Gold Coins and Sweeps Coins?</h3>
                        <p>Gold Coins are for play only in Gold Coin mode, while Sweeps Coins, which can be obtained for free, can be used in Sweeps Coins mode and redeemed as cash prizes.</p>

                        <h3>What Other Companies Use Sweepstakes?</h3>
                        <p>Companies such as McDonald's, Publishers Clearing House, and Pepsi use sweepstakes promotions.</p>

                        <h3>Disclaimer</h3>
                        <p>We only conduct business through this website only; please do not engage in any business through other platforms as it might be fraudulent.</p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default TermsOfUse;