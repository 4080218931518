import React from "react";
import ProductComponent from "../../components/ProductComponent";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const TestForm = () => {
    return (
        <>
            <>
                <Header />
                <ProductComponent />
                <Footer />
            </>
        </>
    )
}

export default TestForm;