import { HiOutlineQuestionMarkCircle } from "react-icons/hi2";
import { MdFileCopy } from "react-icons/md";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { CiWarning } from "react-icons/ci";
import { FaArrowLeft } from "react-icons/fa6";
import bitcoin from '../../images/bitcoin.png'
import lightning from '../../images/lightning.png'
import logo from '../../images/logoicon.png'
import qr from '../../images/qr_code.png';
import venmo from '../../images/venmo.png'
import paypal from '../../images/paypal.png'
import strike from '../../images/strike.svg'
import coinbase from '../../images/coinbase.svg'
import cashapp from '../../images/cashapp.png'
import './BitcoinPayment.css'
import React, { useEffect, useState } from 'react'
import SpeedPayment from "../SpeedComp/SpeedPayment";
import { Link } from "react-router-dom";

function BitcoinPayment() {

  const btcRate = 0.000011;

  const [data, setData] = useState({})

  const getData = async () => {
    setData(JSON.parse(localStorage.getItem('credits')))
  }

  useEffect(() => {
    getData();
  }, [])


  return (
    <main>
      <section>
        <div className="w-100 overflow-hidden">
          <div className="row">
            <div className="col-md-6 col-xs-12 bg-primary text-white ">
              <div className="section__payment ms-auto py-3 px-4">
                <div className="top__banner d-flex align-items-center justify-content-between">
                  <div className="start__banner d-flex gap-2 align-items-center py-2">
                    <Link to="/CheckoutForm" className="text-white">
                      <FaArrowLeft className="fs-3 fw-light" />
                    </Link>
                    <img src={logo} alt="" className="logo__website" />
                    <p className="m-0 fs-4 fw-medium">Bad Skull</p>
                  </div>
                  <HiOutlineQuestionMarkCircle className="fs-4" />
                </div>
                <div className="bitcoin__rate">
                  <h2 className="fw-bold display-5 mt-4">{data.price * data.credits} USD</h2>
                  <p>(≈ ฿ {data.credits * data.price * btcRate})</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xs-12">
              <div className="section__payment pt-4">
                <div className="deposit__curr d-flex flex-column w-75 mx-auto text-secondary">
                  <div className="top__banner__sec__two d-flex justify-content-end">
                    <div className="report__btn border border-secondary-emphasis p-1 rounded-1 ">
                      <Link className="text-secondary text-decoration-none"><CiWarning className="fw-bold" /> Report</Link>
                    </div>
                  </div>
                  <h3 className="fs-6 my-3">Deposit Currency</h3>
                  <div className="border border-secondary-emphasis p-2 text-dark rounded-1 d-flex align-items-center gap-2 bitcoin__active">
                    <img src={bitcoin} alt="" className="curr__logo " />
                    <p className="m-0">BTC</p>
                  </div>

                  <h3 className="fs-6 my-3">Select Currency</h3>
                  <div className="d-flex flex-row gap-2 justify-content-between">
                    <div className="border border-secondary-emphasis text-dark p-2 rounded-1 w-100 d-flex align-items-center gap-2 bitcoin__active">
                      <img src={bitcoin} alt="" className="curr__logo " />
                      <p className="m-0">BTC</p>
                    </div>
                    <div className="border border-secondary-emphasis text-dark p-2 rounded-1  w-100 d-flex align-items-center gap-2">
                      <img src={lightning} alt="" className="curr__logo " />
                      <p className="m-0">Lightning</p>
                    </div>
                  </div>

                  <div className="qr__code border border-secondary-emphasis my-3 rounded-1 p-3">
                    <img src={qr} alt="" className="w-100" />
                    <h6 className="border border-secondary-emphasis rounded-1 overflow hidden mt-2 p-2 px-4 text-center overflow-hidden">
                      bc1q6unkcrfe57t9j2fpfwtqtce78x0zpddqu3y5vf
                    </h6>
                    <div className="btn-grp text-secondary d-flex justify-content-between gap-2">
                      <button className="btn text-secondary w-100 rounded-1 border border-secondary-emphasis">
                        <MdFileCopy />&nbsp; Copy Address
                      </button>
                      <button className="btn text-secondary w-100 rounded-1 border border-secondary-emphasis">
                        <MdOutlineAccountBalanceWallet />&nbsp;Open Wallet
                      </button>
                    </div>
                    < SpeedPayment />
                  </div>

                  <div className="payment__methods d-flex mt-4  mb-3 justify-content-between">
                    <img src={coinbase} alt="" />
                    <img src={venmo} alt="" />
                    <img src={cashapp} alt="" />
                    <img src={paypal} alt="" />
                    <img src={strike} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main >
  )
}

export default BitcoinPayment
