import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "bootstrap/dist/css/bootstrap.min.css";
import './FAQs.css';
const API_URL = "https://api.badskullgaming.com/api/get-faqs";

const FAQs = () => {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch data from API
  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await fetch(API_URL);
        const data = await response.json();

        if (data.status === 200) {
          setFaqs(data.faqs);
        } else {
          console.error("Error fetching FAQs", data);
        }
      } catch (error) {
        console.error("API call failed", error);
      } finally {
        setLoading(false);
      }
    };
    fetchFaqs();
  }, []);




  return (
    <div className="faq_main">
      <div className="container py-5">
        <h1 className="text-center">Help Center</h1>
        <div className="row">
          <div className="col-sm-12 mx-auto col-lg-8 col-md-12">
            {/* Loading State */}
            {loading && <p className="text-center">Loading FAQs...</p>}

            {/* FAQ Section */}
            {!loading && faqs.length > 0 ? (
              <Accordion defaultActiveKey="0" id="faqsAccordion">
                {faqs.map((item, index) => (
                  <Accordion.Item eventKey={index} key={item.id} className="mt-2">
                    <Accordion.Header>{index + 1}. {item.title}</Accordion.Header>
                    <Accordion.Body>
                      <hr />
                      {item.desc}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            ) : (
              !loading && <p className="text-center">No  FAQs available at the moment.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
