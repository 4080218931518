import React from "react";
import { Link } from "react-router-dom";
import logo from '../../images/logo.png';

const Footer = () => {
  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <footer className="footer  bg-dark text-light py-4">
        <div className="container">
          <div className="row">
            {/* bad skull's Playhouse Section */}
            <div className="col-lg-4 mb-4">
              <img src={logo} alt="" height={'100px'} />
              <p className="thrill fs-6">
                Where the thrill of Sweepstakes gaming comes alive in every
                click! Born from a vision to revolutionize online gaming, Bad Skull Fishing Room is more than just a gaming platform.
              </p>
              <p className="thrill fs-6">
                The information you provide will only be used to administer this
                promotion. NO PURCHASE NECESSARY to enter Sweepstakes.
                SWEEPSTAKES ARE VOID WHERE PROHIBITED BY LAW. For detailed
                rules, see{" "}
                <Link to="#" onClick={scrollToTop} className="rules text-primary">
                  Sweepstakes Rules
                </Link>
                .
              </p>
            </div>
            {/* Quick Links Section */}
            <div className="col-lg-4 mb-4 every every-link">
              <h6 className="fw-bold">QUICK LINKS</h6>
              <ol className="list-unstyled">
                <li className="mt-2">
                  <Link
                    to="/Sweepstakes"
                    onClick={scrollToTop}
                    className="text-decoration-none text-primary fs-6 fw-medium"
                  >
                    <i
                      className="fa-solid fa-circle-arrow-right me-2"
                      style={{ color: "#070808" }}
                    />
                    Sweepstakes Rules
                  </Link>
                </li>
                <li className="mt-2">
                  <Link
                    to="/Faqs"
                    onClick={scrollToTop}
                    className="text-decoration-none text-primary fs-6 fw-medium"
                  >
                    <i
                      className="fa-solid fa-circle-arrow-right me-2"
                      style={{ color: "#070808" }}
                    />
                    FAQ
                  </Link>
                </li>
                <li className="mt-2">
                  <Link
                    to="/Support"
                    onClick={scrollToTop}
                    className="text-decoration-none text-primary fs-6 fw-medium"
                  >
                    <i
                      className="fa-solid fa-circle-arrow-right me-2"
                      style={{ color: "#070808" }}
                    />
                    Contact Us
                  </Link>
                </li>
              </ol>
            </div>
            {/* Terms of Service Section */}
            <div className="col-lg-4 mb-4 every every-link">
              <h6 className="fw-bold">TERMS OF SERVICE</h6>
              <ul className="list-unstyled">
                <li className="mt-2">
                  <Link
                    to="/TermsAndConditions"
                    onClick={scrollToTop}
                    className="text-decoration-none text-primary fs-6 fw-medium"
                  >
                    <i
                      className="fa-solid fa-circle-arrow-right me-2"
                      style={{ color: "#070808" }}
                    />
                    Terms and Conditions
                  </Link>
                </li>
                <li className="mt-2">
                  <Link
                    to="/TermsOfUse"
                    onClick={scrollToTop}
                    className="text-decoration-none text-primary fs-6 fw-medium"
                  >
                    <i
                      className="fa-solid fa-circle-arrow-right me-2"
                      style={{ color: "#070808" }}
                    />
                    Terms of Use
                  </Link>
                </li>
                <li className="mt-2">
                  <Link
                    to="/ResponsibleSocialGameplay"
                    onClick={scrollToTop}
                    className="text-decoration-none text-primary fs-6 fw-medium"
                  >
                    <i
                      className="fa-solid fa-circle-arrow-right me-2"
                      style={{ color: "#070808" }}
                    />
                    Responsible Social Gameplay
                  </Link>
                </li>
                <li className="mt-2">
                  <Link
                    to="/PrivacyPolicy"
                    onClick={scrollToTop}
                    className="text-decoration-none text-primary fs-6 fw-medium"
                  >
                    <i
                      className="fa-solid fa-circle-arrow-right me-2"
                      style={{ color: "#070808" }}
                    />
                    Privacy Policy
                  </Link>
                </li>
                <li className="mt-2">
                  <Link
                    to="/AmlPolicy"
                    onClick={scrollToTop}
                    className="text-decoration-none text-primary fs-6 fw-medium"
                  >
                    <i
                      className="fa-solid fa-circle-arrow-right me-2"
                      style={{ color: "#070808" }}
                    />
                    AML Policy
                  </Link>
                </li>
                <li className="mt-2">
                  <Link
                    to="/RefundPolicy"
                    onClick={scrollToTop}
                    className="text-decoration-none text-primary fs-6 fw-medium"
                  >
                    <i
                      className="fa-solid fa-circle-arrow-right me-2"
                      style={{ color: "#070808" }}
                    />
                    Refund Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="text-center py-2">
            <p className="mb-0">
              © 2024 by Bad Skull Fishing Room. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
